import Axios from 'axios'
import { IArchivosEmpresa, IContacto, IRoom, IReservacion } from '@/store/types/empresa'
import { Module, getModule, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import moment from 'moment';
import { isNumber } from 'util';

@Module({
	name: 'empresa',
	namespaced: true,
})
export default class Empresa extends VuexModule {

	// public properties
	public mensaje = ''
	public exitoReservacion = false
	public archivosEmpresa = {} as IArchivosEmpresa
	public reservacion = {
		checkin: moment().add(1, 'days').format('YYYY-MM-DD'),
		checkout: moment().add(2, 'days').format('YYYY-MM-DD'),
		adults: 2,
		children: 0,
		politicas_leidas: false,
		contacto: {
			first_name: '',
			last_name: '',
			email: ''
		} as IContacto,
		room: {} as IRoom
	} as IReservacion

	get GET_MENSAJE() {
		return this.mensaje
	}

	get GET_ARCHIVOS_EMPRESA() {
		return this.archivosEmpresa
	}

	get GET_EXITO_RESERVACION() {
		return this.exitoReservacion
	}

	get GET_RESERVACION() {
		return this.reservacion
	}

	get GET_TOTAL_RESERVACION() {
		try {
			if (this.reservacion && this.reservacion.room) {
				const a = moment(this.reservacion.checkin)
				const b = moment(this.reservacion.checkout)

				const dias = b.endOf('day').diff(a, 'days')   // =1

				let total = 0
				if (!this.reservacion.room.descuento || this.reservacion.room.descuento === null || this.reservacion.room.descuento === 0) {
					total = this.reservacion.room.precio * dias
				} else {
					total = this.reservacion.room.descuento * dias
				}

				let desayuno = 0
				let children = 0
				let adults = 0
				if (this.reservacion.breakfast && this.reservacion.breakfast !== null && this.reservacion.breakfast !== '') {
					const precio = this.reservacion.breakfast.split('$')
					if (precio[1] && precio[1] !== null) {						
						children = parseInt(this.reservacion.children.toString(), 10)
						adults = parseInt(this.reservacion.adults.toString(), 10)
						desayuno = ((adults + children) * parseFloat(precio[1])) * dias;
					}
				}

				this.reservacion.total_room = total
				
				this.reservacion.total = desayuno && desayuno !== null ?  total + desayuno : total

			}
		} catch (ex) {
			this.reservacion.total = 0
		}

		return this.reservacion.total
	}

	// Mutations
	@Mutation
	public SET_MENSAJE(mensaje: string) {
		this.mensaje = mensaje
	}

	@Mutation
	public SET_ARCHIVOS_EMPRESA(a: IArchivosEmpresa) {
		this.archivosEmpresa = a
	}

	@Mutation
	public SET_EXITO_RESERVACION(exito: boolean ) {
		this.exitoReservacion = exito
	}


	@Mutation
	public SET_RESERVACION(r: IReservacion) {
		this.reservacion = r
	}

	@Mutation
	public SET_ROOM_ID(idRoom: number) {
		this.reservacion.room = {
			id: idRoom,

		} as IRoom
	}


	@Action
	public async doGetArchivos() {
		this.context.commit('SET_MENSAJE', '')
		const response = await Axios.get('empresa')
		if (response && response.data && response.data !== null && response.data.codigo === 20000) {
			this.context.commit('SET_ARCHIVOS_EMPRESA', response.data.datos)			
			//this.context.commit('SET_MENSAJE', response.data.mensaje)
		} 
	}

	@Action
	public async doPostContacto(c: IContacto) {
		this.context.commit('SET_MENSAJE', '')
		const response = await Axios.post('empresa/contacto', c)
		if (response && response.data && response.data !== null ) {
			this.context.commit('SET_MENSAJE', response.data.mensaje)
		}
	}

	@Action
	public async doPostReservacion() {
		this.context.commit('SET_MENSAJE', '')
		const response = await Axios.post('empresa/booking', this.reservacion)
		if (response && response.data && response.data !== null) {
			if (response.data.codigo === 20000) {
				this.context.commit('SET_EXITO_RESERVACION', true);
			} else {
				this.context.commit('SET_EXITO_RESERVACION', false);
			}
			this.context.commit('SET_MENSAJE', response.data.mensaje)
		}
	}
}