




































































































// @ is an alias to /src
import Vue from 'vue';
import $i18n from '../../i18n';
import { mapState } from 'vuex';

export default Vue.extend({
	name: 'Footer',
	computed: {
		...mapState('empresa', [
			'archivosEmpresa'
		])
	},
	mounted() {
		// en carpeta public/js
	},
	methods: {

	}
});
