<template>
	<ha-restaurant></ha-restaurant>
</template>
<script>
	// @ is an alias to /src
	import Restaurant from '@/components/restaurant/Restaurant.vue'	

	export default {
		name: 'restaurant',
		components: {
			'ha-restaurant': Restaurant
		},
	metaInfo() {
		
		return {
			title: 'Mantura Bistro Garden',
		}
	}
	};
</script>
