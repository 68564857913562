


















































































// @ is an alias to /src
import Vue from 'vue';
import $i18n from '../../i18n';
import { IContacto } from '@/store/types/empresa';
import ContactLeftPanel from '@/components/contact/ContactLeftPanel.vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
	name: 'Contact',
	components: {
		'ha-contact-left-panel': ContactLeftPanel
	},
	data: () => {
		return {
			contacto: {} as IContacto,
			mensaje: '',
			galleryDir: 'images/galeria/',
			fontColor: '',
			paginationEnabled: true			
		}
	},
	computed: {
		...mapGetters('imagenes', {
			isLoaded: 'GET_IS_LOADED',
			header: 'GET_TARJETAS_S4_E4', // HEADER
		}),
	},
	methods: {
		submitForm() {
			this.mensaje = ''
			this.contacto.language = $i18n.locale
			this.$store.dispatch('empresa/doPostContacto', this.contacto).then(() => {
				this.mensaje = this.$store.getters['empresa/GET_MENSAJE'] // use [] in getters
			});
		},
		getFontColor() {
			if (this.fontColor === '') {
				this.fontColor = 'color:#' + this.header[0].descuento.toString(16) + ' !important';
			}
			return this.fontColor;
		}
	}
});
