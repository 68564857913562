






































// @ is an alias to /src
import Vue from 'vue';
import $i18n from '../../i18n';
import Booking from '../booking/Booking.vue';
import { mapActions } from 'vuex';
import { IGaleria } from '@/store/types/imagenes'

export default Vue.extend({
	name: 'Swiper',
	data: () => {
		return {
			images: new Array<IGaleria>()
		}
	},
	props: {

	},
	components: {
		'ha-booking': Booking
	},
	methods: {
		...mapActions('imagenes', {
		})
	},
	mounted() {
		// const rd = document.createElement('script');
		// rd.setAttribute('src', './js/swiper.js');
		// document.head.appendChild(rd);

		this.$store.dispatch('imagenes/doGetGallerias').then((response: any) => {
			let ok = true;
			// console.log(response)
			if (response && response.data && response.data !== null && response.data.codigo === 20000) {
				this.images = response.data.datos;
			} else if (response && response.data && response.data !== null) {
				this.images = new Array<IGaleria>();
				// todo, set default images
			} else {
				ok = false;
			}
			// en carpeta public/js
			const lrd = document.createElement('script');
			lrd.setAttribute('src', './js/loadSwiper.js');
			document.head.appendChild(lrd);
		})

	}
});
