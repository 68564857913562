<template>
	<ha-rooms></ha-rooms>
</template>
<script>
// @ is an alias to /src
import SiteMenu from '@/components/menu/SiteMenu.vue';
import Rooms from '@/components/rooms/Rooms.vue';
import Footer from '@/components/footer/Footer.vue';
import $i18n from '../i18n';

export default {
	name: 'rooms',
	components: {
		'ha-site-menu': SiteMenu,
		'ha-rooms': Rooms,
		'ha-footer': Footer,
	},
	metaInfo() {
		const locale = this.$i18n.locale
		return {
			title: locale === 'es' ? 'Habitaciones' : 'Rooms',
		}
	},
};
</script>
<style scoped>
</style>