<template>
	<div class="">
		<ha-contact></ha-contact>	
	</div>
</template>
<script>
	// @ is an alias to /src
	import Contact from '@/components/contact/Contact.vue'

	export default {
		name: 'contact',
		components: {
			'ha-contact': Contact
		},
	metaInfo() {
		
		return {
			title: 'Contact',
		}
	}
	};
</script>
