








// @ is an alias to /src
import Vue from 'vue';
import $i18n from '../../i18n';

export default Vue.extend({
	name: 'PageLoader',
	mounted() {

		// Code that will run only after the
		// entire view has been rendered
		this.$nextTick(() => {
			const pageLoader = $('#page-loader');

			const loader = setTimeout(() => {
				//console.log('load');
				if (pageLoader.length > 0) {
					pageLoader.addClass('loaded');
				}
				$(window).trigger('resize');
			}, 10);
		})
	}
});
