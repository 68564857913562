<template>
	<div>
		<section class="breadcrumbs-custom">
			<div class="shell">
				<div class="breadcrumbs-custom__inner">
					<ul class="breadcrumbs-custom__path">
						<li><router-link :to="{ name: 'home'}">{{ $i18n.locale === 'es' ? 'Inicio' : 'Home' }}</router-link></li>
						<li class="active">{{ $i18n.locale === 'es' ? 'Reservaciones' : 'Reservations' }}</li>
					</ul>
				</div>
			</div>
		</section>
		<section class="bg-gray-dark text-center">
			<!-- RD Parallax-->
			<div class="rd-parallax bg-image parallax-header" data-on="false" data-md-on="true" style="background-image: url(images/parallax-1.jpg);">
				<div class="rd-parallax-layer" data-speed="0.35" data-type="media" data-url="images/parallax-3.jpg"></div>
				<div class="rd-parallax-layer" data-speed="0.1" data-type="html">
					<div class="parallax-header__inner">
						<div class="parallax-header__content">
							<div class="shell">
								<div class="range range-xs-center">
									<div class="cell-sm-10 cell-lg-8">
										<h2>{{ $i18n.locale === 'es' ? 'Reservaciones' : 'Booking' }}</h2>
										<h6>{{ $i18n.locale === 'es' ? 'Gracias por para reservar con nosotros' : 'Thank you for booking a room with us' }}</h6>
										<h6>{{ $i18n.locale === 'es' ? 'Por favor espere nuestro contacto para confirmar su reservaci&oacute;n' : 'Please wait for our contact to confirm your booking' }}</h6>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>	
<script>
	// @ is an alias to /src

	export default {
		name: 'BookingFormSuccess',
	};
</script>
