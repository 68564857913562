import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';
import Home from '../views/Home.vue';
import Rooms from '../views/Rooms.vue';
import Restaurant from '../views/Restaurant.vue';
import Contact from '../views/Contact.vue';
import Services from '../views/Services.vue';
import BookingForm from '../views/BookingForm.vue';
import BookingFormSuccess from '../views/BookingFormSuccess.vue';


Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'home',
		component: Home,
	},
	{
		path: '/rooms',
		name: 'rooms',
		component: Rooms
	},
	{
		path: '/restaurant',
		name: 'restaurant',
		component: Restaurant
	},
	{
		path: '/contact',
		name: 'contact',
		component: Contact
	},
	{
		path: '/services',
		name: 'services',
		component: Services
	},
	{
		path: '/booking',
		name: 'booking',
		component: BookingForm
	},
	{
		path: '/bookingSuccess',
		name: 'bookingSuccess',
		component: BookingFormSuccess
	}
];

const router = new VueRouter({
	mode: 'history',
	routes,
	scrollBehavior: (to: Route) => {
		if (to.hash) {
			return {
				selector: to.hash
			};
		} else {
			return { x: 0, y: 0 }
		}
	}
});

export default router;
