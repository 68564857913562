





























































































































// @ is an alias to /src
import Vue from 'vue';
import $i18n from '../../i18n';
import { mapState } from 'vuex';

export default Vue.extend({
	name: 'SiteMenu',
	data() {
		return {
			haSiteMenuReloadKey: 0,				
		}
	},
	computed: {
		...mapState('empresa', [
			'archivosEmpresa'
		])
	},
	mounted() {
		// en carpeta public/js
		// const rd = document.createElement('script');
		// rd.setAttribute('src', './js/rdnavbar.js');
		// document.head.appendChild(rd);
		const lrd = document.createElement('script');
		lrd.setAttribute('src', './js/loadRDNavbar.js');
		document.head.appendChild(lrd);
	},
	methods: {
		changeLang: (lang: string) => {
			if (lang.toLowerCase() === 'english') {
				$i18n.locale = 'en';
			} else {
				$i18n.locale = 'es';
			}
		}
	}
});
