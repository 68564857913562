import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import $ from 'jquery';
import VueI18n from 'vue-i18n';
import i18n from './i18n';
import Meta from 'vue-meta';

Vue.use(Meta, {
	keyName: 'metaInfo',
	attribute: 'data-vue-meta'
});

Vue.use(VueI18n);

Vue.config.productionTip = false;

import Axios from 'axios'
//console.log(process.env.VUE_APP_API_ENDPOINT);
Axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;

Vue.filter('capitalize', (value: string) => {
	if (!value) { return '' }
	value = value.toString()
	return value.charAt(0).toUpperCase() + value.slice(1)
});

new Vue({
	router,
	store,
	i18n,
	render: (h) => h(App)
}).$mount('#app');
