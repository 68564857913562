import Vue from 'vue';
import Vuex from 'vuex'
import Empresa from './modules/empresa'
import Imagenes from './modules/imagenes'
import Newsletter from './modules/newsletter'

import { getModule } from 'vuex-module-decorators'

Vue.use(Vuex)

const store = new Vuex.Store({
	modules: {
		// El nombre del m�dulo debe ser igual al name: '' en el @Module 
		empresa: Empresa, 
		imagenes: Imagenes,
		newsletter: Newsletter
	}
})

export default store

export const EmpresaModule = getModule(Empresa, store)
export const ImagenesModule = getModule(Imagenes, store)
export const NewsletterModule = getModule(Newsletter, store)

