





















































































// @ is an alias to /src
import Vue from 'vue';
import $i18n from '../../i18n';
import { mapGetters } from 'vuex';
import { ITarjeta } from '../../store/types/imagenes';

export default Vue.extend({
	name: 'Services',
	data: () => {
		return {
			galleryDir: 'images/galeria/',
			fontColor: '',
			paginationEnabled: true			
		}
	},
	computed: {
		...mapGetters('imagenes', {
			isLoaded: 'GET_IS_LOADED',
			tarjetasS1E5: 'GET_TARJETAS_S1_E5',
			header: 'GET_TARJETAS_S4_E3', // HEADER
		})
	},
	methods: {
		getFontColor() {
			if (this.fontColor === '') {
				this.fontColor = 'color:#' + this.header[0].descuento.toString(16) + ' !important';
			}
			return this.fontColor;
		}
	},
	mounted() {
		if (this.isLoaded === false) {
			this.$store.dispatch('imagenes/doGetTarjetas').then((response: any) => {
				let ok = true;
				// console.log(response)
				if (response && response.data && response.data !== null && response.data.codigo === 20000) {
					ok = true
				} else if (response && response.data && response.data !== null) {
					ok = true
				} else {
					ok = false
				}
			})
		}
	}
});
