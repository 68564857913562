<template>
	<ha-services></ha-services>
</template>
<script>
// @ is an alias to /src
import Services from '@/components/services/Services.vue';
import $i18n from '../i18n';

export default {
	name: 'services',
	components: {
		'ha-services': Services,
	},
	metaInfo() {
		const locale = this.$i18n.locale
		return {
			title: locale === 'es' ? 'Servicios' : 'Services',
		}
	},
};
</script>
