



























// @ is an alias to /src
import Vue from 'vue';
import $i18n from '../../i18n';
import { mapGetters } from 'vuex';

export default Vue.extend({
	name: 'Newsletter',
	props: {

	},
	data: () => {
		return {				
			mail: ''
		}
	},
	computed: {
		...mapGetters('newsletter', {
			mensaje: 'GET_MENSAJE'
		})
	},
	mounted() {
		// en carpeta public/js
	},
	methods: {
		subscribe() {
			this.$store.dispatch('newsletter/doPostSuscription', { mail: this.mail, lang: $i18n.locale })
		}
	}
});
