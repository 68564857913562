<template>
	<ha-booking-form></ha-booking-form>
</template>
<script>
	// @ is an alias to /src
	import BookingForm from '@/components/booking/BookingForm'
	import $i18n from '../i18n';

	export default {
		name: 'BookingForm',
		components: {
			'ha-booking-form': BookingForm
		},
	metaInfo() {
		const locale = this.$i18n.locale
		return {
			title: locale === 'es' ? 'Reservar' : 'Booking',
		}
	}
	};
</script>
