














































































































































// @ is an alias to /src
import Vue from 'vue';
import $i18n from '../../i18n';
import { mapGetters } from 'vuex';
import { ITarjeta } from '../../store/types/imagenes';

export default Vue.extend({
	name: 'Restaurant',
	props: {

	},
	data: () => {
		return {
			subtitulosS1E4: [] as any[],
			imagesPool: [] as string[],
			indexSelected: 0,
			galleryDir: 'images/galeria/',
			fontColor: '',
			paginationEnabled: true
		}
	},
	methods: {
		getList(item: ITarjeta) {
			return this.$i18n.locale === 'es' ? item.lista.split(/\r?\n/) : item.list.split(/\r?\n/);
		},
		getTarjetasFiltradasPorSubtitulo(subtitulo: string) {
			const lista: ITarjeta[] = new Array<ITarjeta>();

			this.tarjetasS1E4.forEach((t: ITarjeta) => {
				if (t.subtitulo === subtitulo) {
					lista.push(t);
				}
			});

			return lista;
		},
		setListaSubtitulosS1E4(tarjetas: ITarjeta[]) {
			let existe = false;
			let primeraTarjeta = { id: 0 } as ITarjeta;
			tarjetas.forEach((t: ITarjeta) => {
				existe = false;

				if (primeraTarjeta.id === 0) { primeraTarjeta = t }

				this.subtitulosS1E4.forEach((s: any) => {
					if (t.subtitulo && s.subtitulo && t.subtitulo.toUpperCase() === s.subtitulo.toUpperCase()) {
						existe = true;
					}
				});
				if (existe === false) {
					this.subtitulosS1E4.push({ subtitulo: t.subtitulo, subtitle: t.subtitle });
				}
			});
		},
		dynSortBySubtitulo() {
			const sortOrder = 1;

			return (a: ITarjeta, b: ITarjeta) => {
				// a should come before b in the sorted order
				if (a.subtitulo < b.subtitulo) {
					return -1 * sortOrder;
					// a should come after b in the sorted order
				} else if (a.subtitulo > b.subtitulo) {
					return 1 * sortOrder;
					// a and b are the same
				} else {
					return 0 * sortOrder;
				}
			}
		},
		setImagesPool() {
			this.tarjetasS1E4.forEach((t: ITarjeta) => {
				this.imagesPool.push(t.imagen);
			});
		},
		getFontColor() {
			if (this.fontColor === '') {
				this.fontColor = 'color:#' + this.header[0].descuento.toString(16) + ' !important';
			}
			return this.fontColor;
		}
	},
	computed: {
		...mapGetters('imagenes', {
			isLoaded: 'GET_IS_LOADED',
			tarjetasS1E4: 'GET_TARJETAS_S1_E4',
			header: 'GET_TARJETAS_S4_E2', // HEADER
		}),
		...mapGetters('empresa', {
			archivosEmpresa: 'GET_ARCHIVOS_EMPRESA'
		})
	},
	mounted() {
		if (this.isLoaded === false) {
			this.$store.dispatch('imagenes/doGetTarjetas').then((response: any) => {
				let ok = true;
				// console.log(response)
				if (response && response.data && response.data !== null && response.data.codigo === 20000) {
					this.setListaSubtitulosS1E4(this.tarjetasS1E4)
					this.tarjetasS1E4.sort(this.dynSortBySubtitulo())
					this.setImagesPool();
				} else if (response && response.data && response.data !== null) {
					this.setListaSubtitulosS1E4(this.tarjetasS1E4)
					this.tarjetasS1E4.sort(this.dynSortBySubtitulo())
					this.setImagesPool();
				} else {
					ok = false;
				}
			})
		} else {
			this.setListaSubtitulosS1E4(this.tarjetasS1E4)
			this.tarjetasS1E4.sort(this.dynSortBySubtitulo())
			this.setImagesPool();
		}
	}
});
