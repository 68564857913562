<template>
	<div class="">
		<ha-swiper></ha-swiper>
		<ha-tarjeta v-if="tarjetasLoaded" :clave="tarjeta1_2"></ha-tarjeta>
		<ha-tarjeta v-if="tarjetasLoaded" :clave="tarjeta1_3"></ha-tarjeta>
		<ha-tarjeta v-if="tarjetasLoaded" :clave="tarjeta1_4"></ha-tarjeta>
		<ha-tarjeta v-if="tarjetasLoaded" :clave="tarjeta1_5"></ha-tarjeta>
		<ha-tarjeta v-if="tarjetasLoaded" :clave="tarjeta1_6"></ha-tarjeta>
		<ha-map></ha-map>
		<ha-newsletter></ha-newsletter>
	</div>
</template>
<script>
	// @ is an alias to /src
	import { mapState } from 'vuex';
	import Tarjeta from '@/components/tarjetas/Tarjeta.vue';
	import Map from '@/components/map/Map.vue';
	import Swiper from '@/components/swiper/Swiper.vue';
	import Newsletter from '@/components/newsletter/Newsletter.vue';

	export default {
		name: 'home',
		data: () => {
			return {
				tarjeta1_2: '1.2',
				tarjeta1_3: '1.3',
				tarjeta1_4: '1.4',
				tarjeta1_5: '1.5',
				tarjeta1_6: '1.6',
				tarjetasLoaded: false
			}
		},
		methods: {
		},
		computed: {
			...mapState('imagenes', [
			])
		},
		components: {
			'ha-swiper': Swiper,
			'ha-tarjeta': Tarjeta,
			'ha-map': Map,
			'ha-newsletter': Newsletter
		},
		mounted() {
			this.$store.dispatch('imagenes/doGetTarjetas').then(() => {
				this.tarjetasLoaded = true;
			});
		}
	};
</script>
<style scoped>
	.price {
		font-size: 18px !important;
		vertical-align: baseline !important;
		top: 0px !important;
	}
	.price__main {
		font-size: 18px !important;
		vertical-align: baseline !important;
	}
	.price__aside_top {
		top: inherit !important;
		font-size: 18px !important;
		vertical-align: baseline !important;
		top: -0.1em !important;
	}

</style>