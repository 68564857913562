





























































































































































// @ is an alias to /src
import Vue from 'vue';
import $i18n from '../../i18n';
import { mapState, mapGetters } from 'vuex';
import { ITarjeta } from '@/store/types/imagenes';
import { IReservacion, IRoom } from '../../store/types/empresa';
import moment from 'moment';

export default Vue.extend({
	name: 'Tarjeta',
	props: {
		clave: {
			type: String,
			required: true
		}
	},
	data: () => {
		return {
			subtitulosS1E4: [] as any[],
			theImage: '',
			theFilter: '',
			galleryDir: 'images/galeria/',
			reservacion: {
				checkin: moment().add(1, 'days').format('YYYY-MM-DD'),
				checkout: moment().add(2, 'days').format('YYYY-MM-DD'),
				adults: 2,
				children: 0
			} as IReservacion
		}
	},
	methods: {
		getList(item: ITarjeta) {
			return this.$i18n.locale === 'es' ? item.lista.split(/\r?\n/) : item.list.split(/\r?\n/);
		},
		getTarjetasFiltradasPorSubtitulo(subtitulo: string) {
			const lista: ITarjeta[] = new Array<ITarjeta>();

			this.tarjetasS1E4.forEach((t: ITarjeta) => {
				if (t.subtitulo === subtitulo) {
					lista.push(t);
				}
			});

			return lista;
		},
		setListaSubtitulosS1E4(tarjetas: ITarjeta[]) {
			let existe = false;
			let primeraTarjeta = { id: 0 } as ITarjeta;
			tarjetas.forEach((t: ITarjeta) => {
				existe = false;

				if (primeraTarjeta.id === 0) { primeraTarjeta = t }

				this.subtitulosS1E4.forEach((s: any) => {
					if (t.subtitulo && s.subtitulo && t.subtitulo.toUpperCase() === s.subtitulo.toUpperCase()) {
						existe = true;
					}
				});
				if (existe === false) {
					this.subtitulosS1E4.push({ subtitulo: t.subtitulo, subtitle: t.subtitle });
				}
			});
			this.setImageS1E4(primeraTarjeta.imagen);

		},
		setImageS1E4(image: string) {
			this.theImage = image;
		},
		setImageS1E4FromFirstInGroup(subtitulo: string) {
			let setOK = false
			this.tarjetasS1E4.forEach((t: ITarjeta) => {
				if (t.subtitulo.toUpperCase() === subtitulo.toUpperCase()) {
					if (setOK === false) {
						this.setImageS1E4(t.imagen);
						setOK = true;
					}
				}
			});
		},
		booking(room: ITarjeta) {
			// first we store reservacion and then we navigate
			this.reservacion.room = {
				id: room.id
			} as IRoom
			localStorage.setItem('reservacion', JSON.stringify(this.reservacion))
			this.$router.push('booking')

		}
	},
	computed: {
		...mapState('imagenes', [
			'seccionS1E2',
			'seccionS1E3',
			'seccionS1E4',
			'seccionS1E5',
			'seccionS1E6'
		]),
		...mapGetters('imagenes', {
			isLoaded: 'GET_IS_LOADED',
			//tarjetas: 'GET_TARJETAS', // lo hacemos en el mounted
			tarjetasS1E2: 'GET_TARJETAS_S1_E2',
			tarjetasS1E3: 'GET_TARJETAS_S1_E3',
			tarjetasS1E4: 'GET_TARJETAS_S1_E4',
			tarjetasS1E5: 'GET_TARJETAS_S1_E5',
			tarjetasS1E6: 'GET_TARJETAS_S1_E6',
		}),
		...mapGetters('empresa', {
			archivosEmpresa: 'GET_ARCHIVOS_EMPRESA'
		})
	},
	mounted() {
		if (this.clave === this.seccionS1E4) {
			// const rd = document.createElement('script');
			// rd.setAttribute('src', './js/isotope.js');
			// document.head.appendChild(rd);

			if (this.isLoaded === false) {
				this.$store.dispatch('imagenes/doGetTarjetas').then((response: any) => {
					let ok = true;
					// console.log(response)
					if (response && response.data && response.data !== null && response.data.codigo === 20000) {
						this.setListaSubtitulosS1E4(response.data.datos);
					} else if (response && response.data && response.data !== null && response.data.codigo !== 20000) {
						ok = true;
					} else {
						ok = false;
					}
				})
			} else {
				// solo cargar los subtítulos
				this.setListaSubtitulosS1E4(this.tarjetasS1E4);
			}
			// en carpeta public/js
			const lrd = document.createElement('script');
			lrd.setAttribute('src', './js/loadIsotope.js');
			document.head.appendChild(lrd);
		} else {
			if (this.isLoaded === false) {
				this.$store.dispatch('imagenes/doGetTarjetas').then((response: any) => {
					let ok = true;
					// console.log(response)
					if (response && response.data && response.data !== null && response.data.codigo === 20000) {
						ok = true
					} else if (response && response.data && response.data !== null) {
						ok = true
					} else {
						ok = false;
					}
				})
				this.$store.dispatch('imagenes/doGetTarjetasImagenes').then((response: any) => {
					let ok = true;
					// console.log(response)
					if (response && response.data && response.data !== null && response.data.codigo === 20000) {
						ok = true
					} else if (response && response.data && response.data !== null) {
						ok = true
					} else {
						ok = false;
					}
				})
			}
		}

		const a = localStorage.getItem('reservacion') && localStorage.getItem('reservacion') !== null ? localStorage.getItem('reservacion')!.toString() : '';
		if (a !== '') {
			const b = JSON.parse(a) as IReservacion
			if (b) {
				this.reservacion = b;
			}
		}
	}
});
