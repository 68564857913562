import Axios from 'axios'
import { IParametrosSuscription } from '@/store/types/newsletter'
import { Module, getModule, VuexModule, Mutation, Action } from 'vuex-module-decorators'

@Module({
	name: 'newsletter',
	namespaced: true,
})
export default class Newsletter extends VuexModule {

	// public properties
	public mensaje = ''

	get GET_MENSAJE() {
		return this.mensaje
	}

	// Mutations
	@Mutation
	public SET_MENSAJE(mensaje: string) {
		this.mensaje = mensaje
	}

	@Action
	public async doPostSuscription(payload: IParametrosSuscription) {
		this.context.commit('SET_MENSAJE', '')
		const response = await Axios.post('newsletter/subscribe', payload)
		if (response && response.data && response.data !== null) {
			const datos = response.data.datos
			this.context.commit('SET_MENSAJE', response.data.mensaje)
		} 
	}
}