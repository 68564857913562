








import Vue from 'vue';
import PageLoader from '@/components/pageLoader/PageLoader.vue';
import SiteMenu from '@/components/menu/SiteMenu.vue';
import Footer from '@/components/footer/Footer.vue';
import $i18n from './i18n';
import { MetaInfo } from 'vue-meta';

export default Vue.extend({
	name: 'app',
	data() {
		return {
			
			description: '',
			title: '',
			theTemplate: ''
		}
	},
	components: {
		'ha-page-loader': PageLoader,
		'ha-site-menu': SiteMenu,
		'ha-footer': Footer
	},
	mounted() {
		this.description = this.$i18n && this.$i18n.locale && this.$i18n.locale === 'en' ? 'Somos un encantador hotel con más de 20 años de experiencia que le ofrece el mejor confort. Estratégicamente ubicado en el corazón de Chetumal para brindarle un servicio cordial y las habitaciones más cómodas para su estadía, tomando en cuenta que en su estancia sus necesidades sean cubiertas con el servicio que nos caracteriza: internet de alta velocidad y excelente room service. Hotel & Suites Arges es un lugar que reúne y acobija a las personas que viajan por el caribe ya sea para vacacionar o por trabajo.' : 'We are a lovely hotel with more than 20 years of experience that offers you the best comfort. Strategically located in the heart of Chetumal to provide you a friendly service and the more comfortable rooms for your stay always making sure that you will have any of your needs satisfied, be it a comfortable hotel room with strong WiFi signal and a diligent room service. Hotel & Suites Arges is a welcoming retreat both for people traveling across the country and for businessmen visiting Chetumal for some commercial affairs.';
		this.title = this.$i18n.locale === 'es' ? 'Inicio' : 'Home';
		this.theTemplate = 'Hotel & Suites Arges Chetumal | %s';

		this.$store.dispatch('empresa/doGetArchivos');

		// const lrd = document.createElement('script');
		// lrd.setAttribute('src', 'https://cdn.guruhotel.com/checkout/js/checkout-min.js');
		// document.head.appendChild(lrd);

	},
	metaInfo(): MetaInfo {
		const locale = this.$i18n.locale;
		const title = this.title;
		//let theTemplate = this.theTemplate;
		const description = this.description;
		return {
			// Children can override the title.
			title: locale === 'es' ? 'Inicio' : 'Home',
			// Result: My Page Title ← My Site
			// If a child changes the title to "My Other Page Title",
			// it will become: My Other Page Title ← My Site
			titleTemplate: 'Hotel & Suites Arges Chetumal | %s',
			// Define meta tags here.
			meta: [
				{charset: 'utf-8'},
				{name: 'viewport', content: 'width=device-width, height=device-height, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'},
				{name: 'description', content:  description},
				{name: 'format-detection', content: 'telephone=no'},
				{property: 'og:title', content: 'Hotel & Suites Arges Chetumal | %s'},
				{property: 'og:site_name', content: 'Inicio'},
				// The list of types is available here: http://ogp.me/#types
				{property: 'og:type', content: 'website'},
				// Should the the same as your canonical link, see below.
				{property: 'og:url', content: 'https://hotelarges.com'},
				{property: 'og:image', content: 'https://hotelarges.com/images/logo.png'},
				// Often the same as your meta description, but not always.
				{property: 'og:description', content: description},

				// Twitter card
				{name: 'twitter:card', content: description},
				{name: 'twitter:site', content: 'https://hotelarges.com'},
				{name: 'twitter:title', content: 'Hotel & Suites Arges Chetumal | %s'},
				{name: 'twitter:description', content: description},
				// Your twitter handle, if you have one.
				{name: 'twitter:creator', content: '@hotelarges'},
				{name: 'twitter:image:src', content: 'https://hotelarges.com/images/logo.png'},

				// Google / Schema.org markup:
				{itemprop: 'name', content: 'Hotel & Suites Arges Chetumal | %s'},
				{itemprop: 'description', content: description},
				{itemprop: 'image', content: 'https://hotelarges.com/images/logo.png'}
			]
			
		}
	}
});
