



































// @ is an alias to /src
import Vue from 'vue';
import $i18n from '../../i18n';

export default Vue.extend({
	name: 'Map',
	props: {

	},
	data: () => {
		return {
			from: 'airport',
			fromAirport: 'airport',
			fromFreeZone: 'freezone'
		}
	},
	mounted() {
		// en carpeta public/js
	},
	methods: {

	}
});
