
























































































































































































































// @ is an alias to /src
import Vue from 'vue';
import $i18n from '../../i18n';
import { mapGetters } from 'vuex';
import { ITarjeta, ITarjetaImagen } from '../../store/types/imagenes';
import { Carousel, Slide } from 'vue-carousel';
// import VueSlickCarousel from 'vue-slick-carousel';
// import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

export default Vue.extend({
	name: 'Rooms',
	data() {
		return {
			isAllLoaded: false,
			galleryDir: 'images/galeria/',
			fontColor: '',
			paginationEnabled: true,
			settings: {
				arrows: true,
				dots: true,
				infinite: true,
				centerMode: true,
				centerPadding: '20px',
				slidesToShow: 1,
				slidesToScroll: 1,
				variableWidth: false
			}
		};
	},
	methods: {
		getList(item: ITarjeta) {
			return this.$i18n.locale === 'es'
				? item.lista.split(/\r?\n/)
				: item.list.split(/\r?\n/);
		},
		booking(room: ITarjeta) {
			this.$store.commit('empresa/SET_ROOM_ID', room.id);
			this.$router.push('booking');
		},

		getImagenesPorTarjetaId(id: number) {
			return this.tarjetasImagenesS1E3.filter(
				(i: ITarjetaImagen) => i.id_tarjeta === id
			);
		},
		getRutaImagenDeTarjeta(i: ITarjetaImagen) {
			if (i && i.imagen) {
				return this.galleryDir + i.imagen;
			} else {
				return '#'
			}
		},
		getFontColor() {
			if (this.fontColor === '') {
				this.fontColor = 'color:#' + this.header[0].descuento.toString(16) + ' !important';
			}
			return this.fontColor;
		}
	},
	components: {
		Carousel,
		Slide,
//		VueSlickCarousel
	},
	computed: {
		...mapGetters('imagenes', {
			isLoaded: 'GET_IS_LOADED',
			isLoading: 'GET_IS_LOADING',
			tarjetasS1E3: 'GET_TARJETAS_S1_E3',
			tarjetasImagenesS1E3: 'GET_TARJETAS_IMAGENES_S1_E3',
			header: 'GET_TARJETAS_S4_E1', // HEADER
		}),
		...mapGetters('empresa', {
			mensaje: 'GET_MENSAJE',
			reservacion: 'GET_RESERVACION',
			archivosEmpresa: 'GET_ARCHIVOS_EMPRESA',
		}),
	},
	mounted() {
		//const rd = document.createElement('script');
		//rd.setAttribute('src', './js/photoswipe.js');
		//document.head.appendChild(rd);

		if (this.isLoaded === false && this.isLoading === false) {
			this.$store.dispatch('imagenes/doGetTarjetas').then((response: any) => {
				let ok = true;
				this.isAllLoaded = true;
				// console.log(response)
				if (
					response &&
					response.data &&
					response.data !== null &&
					response.data.codigo === 20000
				) {
					ok = true;          
				} else if (response && response.data && response.data !== null) {
					ok = true;
				} else {
					ok = false;
				}
				// en carpeta public/js
				//const lrd = document.createElement('script');
				//lrd.setAttribute('src', './js/loadPhotoswipe.js');
				//setTimeout(() => document.head.appendChild(lrd), 2000);
			});
			this.$store
				.dispatch('imagenes/doGetTarjetasImagenes')
				.then((response: any) => {
					let ok = true;
					// console.log(response)
					if (
						response &&
						response.data &&
						response.data !== null &&
						response.data.codigo === 20000
					) {
						ok = true;
					} else if (response && response.data && response.data !== null) {
						ok = true;
					} else {
						ok = false;
					}
				});
		} else {
			// en carpeta public/js
			//const lrd = document.createElement('script');
			//lrd.setAttribute('src', './js/loadPhotoswipe.js');
			//setTimeout(() => document.head.appendChild(lrd), 2000);
		}
	},
});
