








































































// @ is an alias to /src
import Vue from 'vue';
import $i18n from '../../i18n';

export default Vue.extend({
	name: 'ContactLeftPanel',
});
