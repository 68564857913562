import Axios from 'axios'
import { IState, IRespuestaApi } from '@/store/types/comun'
import { IGaleria, ITarjeta, ITarjetaImagen } from '@/store/types/imagenes'
import { Module, getModule, VuexModule, Mutation, Action } from 'vuex-module-decorators'

@Module({
	name: 'imagenes',
	namespaced: true,
})
export default class Imagenes extends VuexModule implements IState {

	// public properties
	public error = ''
	public isLoaded = false
	public isLoading = false
	// control de valores de seccion
	public seccionS1E2: string = '1.2'
	public seccionS1E3: string = '1.3'
	public seccionS1E4: string = '1.4'
	public seccionS1E5: string = '1.5'
	public seccionS1E6: string = '1.6'
	public seccionS2E1: string = '2.1'
	public seccionS4E1: string = '4.1'
	public seccionS4E2: string = '4.2'
	public seccionS4E3: string = '4.3'
	public seccionS4E4: string = '4.4'
	public seccionS4E5: string = '4.5'

	// private properties
	private LOCALSTORAGE_NAME_TARJETAS: string = 'ha-tarjetas';
	private galerias = new Array<IGaleria>()
	private tarjetas = new Array<ITarjeta>()
	private tarjetasS1E2 = new Array<ITarjeta>()
	private tarjetasS1E3 = new Array<ITarjeta>()
	private tarjetasImagenesS1E3 = new Array<ITarjetaImagen>()
	private tarjetasS1E4 = new Array<ITarjeta>()
	private tarjetasS1E5 = new Array<ITarjeta>()
	private tarjetasS1E6 = new Array<ITarjeta>()
	private tarjetasS2E1 = new Array<ITarjeta>()
	private tarjetasS4E1 = new Array<ITarjeta>()
	private tarjetasS4E2 = new Array<ITarjeta>()
	private tarjetasS4E3 = new Array<ITarjeta>()
	private tarjetasS4E4 = new Array<ITarjeta>()
	private tarjetasS4E5 = new Array<ITarjeta>()

	// Getters
	get GET_IS_LOADED(): boolean {
		return this.isLoaded
	}

	get GET_IS_LOADING(): boolean {
		return this.isLoading
	}

	get GET_LOCALSTORAGE_NAME_TARJETAS(): string {
		return this.LOCALSTORAGE_NAME_TARJETAS
	}

	get GET_GALERIAS() {
		return this.galerias || new Array<IGaleria>()
	}

	get GET_TARJETAS() {
		return this.tarjetas || new Array<ITarjeta>()
	}

	get GET_TARJETAS_S1_E2() {
		return this.tarjetasS1E2 || new Array<ITarjeta>()
	}

	get GET_TARJETAS_S1_E3() {
		return this.tarjetasS1E3 || new Array<ITarjeta>()
	}

	get GET_TARJETAS_IMAGENES_S1_E3() {
		return this.tarjetasImagenesS1E3 || new Array<ITarjetaImagen>()
	}

	get GET_TARJETAS_S1_E4() {
		return this.tarjetasS1E4 || new Array<ITarjeta>()
	}

	get GET_TARJETAS_S1_E5() {
		return this.tarjetasS1E5 || new Array<ITarjeta>()
	}
	get GET_TARJETAS_S1_E6() {
		return this.tarjetasS1E6 || new Array<ITarjeta>()
	}

	get GET_TARJETAS_S2_E1() {
		return this.tarjetasS2E1 || new Array<ITarjeta>()
	}

	get GET_TARJETAS_S4_E1() {
		return this.tarjetasS4E1 || new Array<ITarjeta>()
	}

	get GET_TARJETAS_S4_E2() {
		return this.tarjetasS4E2 || new Array<ITarjeta>()
	}

	get GET_TARJETAS_S4_E3() {
		return this.tarjetasS4E3 || new Array<ITarjeta>()
	}

	get GET_TARJETAS_S4_E4() {
		return this.tarjetasS4E4 || new Array<ITarjeta>()
	}

	get GET_TARJETAS_S4_E5() {
		return this.tarjetasS4E5 || new Array<ITarjeta>()
	}

	// Mutations
	@Mutation
	public SET_ERROR(error: string) {
		this.error = error
	}

	@Mutation
	public SET_IS_LOADED(isLoaded: boolean) {
		this.isLoaded = isLoaded
	}

	@Mutation
	public SET_IS_LOADING(isLoading: boolean) {
		this.isLoading = isLoading
	}

	@Mutation
	public SET_TARJETAS(tarjetas: ITarjeta[]) {
		this.tarjetas = tarjetas
	}

	@Mutation
	public SET_TARJETAS_S1_E2(tarjetas: ITarjeta[]) {
		this.tarjetasS1E2 = this.tarjetas.filter((f: ITarjeta) => f.clave_grupo === this.seccionS1E2)
	}

	@Mutation
	public SET_TARJETAS_S1_E3(tarjetas: ITarjeta[]) {
		this.tarjetasS1E3 = this.tarjetas.filter((f: ITarjeta) => f.clave_grupo === this.seccionS1E3)
	}

	@Mutation
	public SET_TARJETAS_IMAGENES_S1_E3(tarjetas: ITarjetaImagen[]) {
		this.tarjetasImagenesS1E3 = tarjetas
	}


	@Mutation
	public SET_TARJETAS_S1_E4(tarjetas: ITarjeta[]) {
		this.tarjetasS1E4 = this.tarjetas.filter((f: ITarjeta) => f.clave_grupo === this.seccionS1E4)
	}

	@Mutation
	public SET_TARJETAS_S1_E5(tarjetas: ITarjeta[]) {
		this.tarjetasS1E5 = this.tarjetas.filter((f: ITarjeta) => f.clave_grupo === this.seccionS1E5)
	}

	@Mutation
	public SET_TARJETAS_S1_E6(tarjetas: ITarjeta[]) {
		this.tarjetasS1E6 = this.tarjetas.filter((f: ITarjeta) => f.clave_grupo === this.seccionS1E6)
	}

	@Mutation
	public SET_TARJETAS_S2_E1(tarjetas: ITarjeta[]) {
		this.tarjetasS2E1 = this.tarjetas.filter((f: ITarjeta) => f.clave_grupo === this.seccionS2E1)
		this.tarjetasS2E1.forEach((t: ITarjeta) => {
			t.titulo = t.titulo.toUpperCase()
			t.title = t.titulo.toUpperCase()
		})
	}

	@Mutation
	public SET_TARJETAS_S4_E1(tarjetas: ITarjeta[]) {
		this.tarjetasS4E1 = this.tarjetas.filter((f: ITarjeta) => f.clave_grupo === this.seccionS4E1)
	}

	@Mutation
	public SET_TARJETAS_S4_E2(tarjetas: ITarjeta[]) {
		this.tarjetasS4E2 = this.tarjetas.filter((f: ITarjeta) => f.clave_grupo === this.seccionS4E2)
	}

	@Mutation
	public SET_TARJETAS_S4_E3(tarjetas: ITarjeta[]) {
		this.tarjetasS4E3 = this.tarjetas.filter((f: ITarjeta) => f.clave_grupo === this.seccionS4E3)
	}

	@Mutation
	public SET_TARJETAS_S4_E4(tarjetas: ITarjeta[]) {
		this.tarjetasS4E4 = this.tarjetas.filter((f: ITarjeta) => f.clave_grupo === this.seccionS4E4)
	}

	@Mutation
	public SET_TARJETAS_S4_E5(tarjetas: ITarjeta[]) {
		this.tarjetasS4E5 = this.tarjetas.filter((f: ITarjeta) => f.clave_grupo === this.seccionS4E5)
	}

	@Action
	public async doGetGallerias() {		
		return await Axios.get('galerias/public')
	}

	@Action
	public async doGetTarjetas() {
		this.context.commit('SET_ERROR', '')
		this.context.commit('SET_IS_LOADED', false)
		this.context.commit('SET_IS_LOADING', true)
		const response = await Axios.get('tarjetas/public')
		this.context.commit('SET_IS_LOADING', false)
		if (response && response.data && response.data !== null && response.data.codigo === 20000) {
			this.context.commit('SET_IS_LOADED', true)
			const datos = response.data.datos
			this.context.commit('SET_TARJETAS', datos)
			this.context.commit('SET_TARJETAS_S1_E2')
			this.context.commit('SET_TARJETAS_S1_E3')
			this.context.commit('SET_TARJETAS_S1_E4')
			this.context.commit('SET_TARJETAS_S1_E5')
			this.context.commit('SET_TARJETAS_S1_E6')
			this.context.commit('SET_TARJETAS_S2_E1')
			this.context.commit('SET_TARJETAS_S4_E1')
			this.context.commit('SET_TARJETAS_S4_E2')
			this.context.commit('SET_TARJETAS_S4_E3')
			this.context.commit('SET_TARJETAS_S4_E4')
			this.context.commit('SET_TARJETAS_S4_E5')
			/* tslint:disable:no-string-literal */
			localStorage.setItem(this.context.getters['GET_LOCALSTORAGE_NAME_TARJETAS'], JSON.stringify(datos))
			/* tslint:enable:no-string-literal */
		} else {
			// get some static cards build for errors
		}
	}

	@Action
	public async doGetTarjetasImagenes() {
		this.context.commit('SET_ERROR', '')
		this.context.commit('SET_IS_LOADED', false)
		this.context.commit('SET_IS_LOADING', true)
		const response = await Axios.get('tarjetas/imagenes/public')
		this.context.commit('SET_IS_LOADING', false)
		if (response && response.data && response.data !== null && response.data.codigo === 20000) {
			this.context.commit('SET_IS_LOADED', true)
			const datos = response.data.datos
			this.context.commit('SET_TARJETAS_IMAGENES_S1_E3', datos)
			/* tslint:disable:no-string-literal */
			localStorage.setItem(this.context.getters['GET_LOCALSTORAGE_NAME_TARJETAS_IMAGENES'], JSON.stringify(datos))
			/* tslint:enable:no-string-literal */
		} else {
			// get some static cards build for errors
		}
	}
}